
import { defineComponent } from 'vue'
import { BillDetailDto, BillDto } from '@/api/bill'
import { getPayrollList, payrollDelete } from '@/api/payroll'
import { exportPayroll } from '@/api/export'
import batchPayroll from '@/components/batch-payroll.vue'
import user from '../utils/user'
import store from '@/store/index'
import axios from 'axios'
import { downLoadFile } from '../utils/util'
export default defineComponent({
    name: 'Payroll',
    components: {
        batchPayroll
    },
    data () {
        return {
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            accountExpireInfoStatus: 0
        }
    },
    created () {
        this.getPayrollList()
        user.waitLogin().then(() => {
            this.accountExpireInfoStatus = user.accountExpireInfoStatus
            console.log(this.accountExpireInfoStatus, user.accountExpireInfoStatus, '点击啦')
        })
    },
    methods: {
        async getPayrollList () {
            const result = await getPayrollList({
                searchText: this.searchValue,
                pageSize: this.pageSize,
                pageNum: this.pageNum
            })
            this.total = result.total
            this.tableData = result.list
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getPayrollList()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getPayrollList()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        /**
         * 导出勾选
         */
        async onExport () {
            let type1 = 'download'
            let type2 = 'fileName'
            if (this.selects.length > 1) {
                type1 = 'downloadZip'
                type2 = 'uuid'
            }
            const str = this.selects.join(',')
            const result = await exportPayroll({
                ids: str,
                searchText: this.searchText
            }, {
                loading: true
            })
            if (this.selects.length > 1) {
                result.type = 'Zip'
            }
            console.log(result, 'result')
            if (!result.fileUrl && !result.exportStatus) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        },
        /**
         * 删除勾选工资单
         */
        async onDelete () {
            const str = this.selects.join(',')
            if (str.length === 0) {
                this.$message.warning('请至少选择一个！')
                return
            }
            await payrollDelete(str)
            this.getPayrollList()
            this.$message.success('删除成功！')
        }
    }
})
