import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "container block block-alone" }
const _hoisted_5 = { class: "header height-right" }
const _hoisted_6 = { class: "search" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "table" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_export_button = _resolveComponent("export-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batchPayroll = _resolveComponent("batchPayroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "请输入工资单名称",
            class: "input search-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
          }, null, 544), [
            [_vModelText, _ctx.searchText]
          ]),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
          }, "搜索"),
          _createElementVNode("button", {
            class: "btn-white",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
          }, "重置")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_tabs, { "model-value": "detail" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: _normalizeClass(['btn-green','download-btn',_ctx.accountExpireInfoStatus === 3 ? 'btn-active' : ''] ),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isBatchPay = true)),
                disabled: _ctx.accountExpireInfoStatus === 3
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("i", { class: "el-icon el-icon-download" }, null, -1),
                _createTextVNode(" 上传工资单 ")
              ]), 10, _hoisted_7),
              _createVNode(_component_export_button, {
                style: {"width":"initial"},
                onExport: _ctx.onExport
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" 导出勾选 ")
                ])),
                _: 1
              }, 8, ["onExport"]),
              _createElementVNode("button", {
                class: "btn btn-red",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onDelete && _ctx.onDelete(...args)))
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("i", { class: "el-icon el-icon-delete" }, null, -1),
                _createTextVNode(" 删除 ")
              ]))
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              style: {"width":"100%"},
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              onSelectionChange: _ctx.handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "selection" }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: "工资单名称",
                  width: "200px"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "workYear",
                  label: "发薪年份"
                }),
                _createVNode(_component_el_table_column, {
                  label: "发薪月份",
                  prop: "workMonth"
                }),
                _createVNode(_component_el_table_column, {
                  width: "240px",
                  prop: "serveCompanyName",
                  label: "派送派遣单位名称"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "peopleNum",
                  label: "人数"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "totalMoney",
                  label: "发薪总额"
                }),
                _createVNode(_component_el_table_column, { label: "发薪明细" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("p", {
                      style: {"color":"#1989FA"},
                      class: "point",
                      onClick: ($event: any) => (
                                    _ctx.$router.push({
                                        path: '/payroll/detail/' + scope.row.id
                                    })
                                )
                    }, " 查看详情 ", 8, _hoisted_9)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"]),
            _createVNode(_component_el_pagination, {
              class: "pagination",
              currentPage: _ctx.pageNum,
              "onUpdate:currentPage": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pageNum) = $event)),
              "page-size": _ctx.pageSize,
              "onUpdate:pageSize": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pageSize) = $event)),
              "page-sizes": [10, 30, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.total,
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.getPayrollList
            }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
          ])
        ]),
        _: 1
      }),
      (_ctx.isBatchPay)
        ? (_openBlock(), _createBlock(_component_batchPayroll, {
            key: 0,
            onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isBatchPay = false)),
            onComplete: _ctx.getPayrollList,
            isType: "payroll"
          }, null, 8, ["onComplete"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}